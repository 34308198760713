/* Define the font-face for Gotham Light */
@font-face {
    font-family: 'Gotham Light';
    src: url('../../Gotham-Font/Gotham-Light.otf') format('opentype');
    font-weight: 100; /* Light weight */
    font-style: normal;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @font-face {
    font-family: 'Gotham Bold';
    src: url('../../Gotham-Font/Gotham-Bold.otf') format('opentype');
    font-weight: bold; /* Bold weight */
    font-style: normal;
  }
  /* Specific to input fields in the login form */
.login-form input:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-text-fill-color: #EED8D1 !important; /* Force text color */
  color: #EED8D1 !important; /* Set the desired text color */
  transition: background-color 5000s ease-in-out 0s; /* Ensures no change in background color */
  font-family: 'Gotham Light', sans-serif;
}

/* Specific for Chrome autofill border */
.login-form input:-webkit-autofill::first-line {
  color: #EED8D1 !important; /* Set the desired text color */
  -webkit-text-fill-color: #EED8D1 !important; /* Force text color */
  font-family: 'Gotham Light', sans-serif;
}


  /* Apply Gotham Light to the form and all text */
  body, .login-form input, .login-form select, .waitlist-text-login {
    font-family: 'Gotham Light', sans-serif;
  }
  
  /* Apply Gotham Bold to buttons */
  .login-button, .login-heading, .login-submit-button, .waitlist-button-login
   {
    font-family: 'Gotham Bold', sans-serif;
  }
  .login-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    background-image: url(../../Assets/1@4x\ 1.png);
  }
  
  .background-image-desktop-login {
    position: absolute;
    top: 25%; /* Adjusted for better positioning */
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
    height: auto;
    object-fit: cover;
    margin-top: 20px; /* Add spacing below the background image */
    z-index: 0;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; /* Keep it above other elements */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a background color for clarity */
    padding: 10px 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .login-content {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 300px; /* Adjusted for Navbar height */
  }
  
  /* Other CSS rules */
  
  .login-heading {
    color: #EED8D1;
    margin-bottom: 20px;
    text-decoration: underline;
    font-size: 15px;
  }
  
  .login-form {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #EED8D1;
    border-radius: 30px;
    padding: 0 20px;
    width: 270px;
    margin: 0 auto;
    display: inline-block;
    box-sizing: border-box;
  }
  
  .login-form input,
  .login-form select {
    width: calc(100% - 27px);
    padding: 10px;
    margin-bottom: 0;
    border: none;
    border-bottom: 2px solid #EED8D1;
    background-color: transparent;
    color: #EED8D1;
    font-size: 13px;
    outline: none;
    font-family: 'Gotham Light', sans-serif;
    border-radius: 0; /* Remove corner radius */
  }
  
  
  .login-form input::placeholder,
  .login-form select {
    color: #EED8D1;
  }
  
  .select-wrapper {
    position: relative;
  }
  
  .select-wrapper select {
    border-bottom: 2px solid #EED8D1;
    color: #EED8D1;
    font-size: 16px;
    background: transparent;
    appearance: none;
    outline: none;
    padding: 15px;
    padding-right: 40px;
    width: 103%;
    box-sizing: border-box;
  }
  
  .country-select {
    padding: 15px;
  }
  
  .dropdown-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #EED8D1;
    font-size: 24px;
  }
  
  .dropdown-icon2 {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjusted for better alignment */
    transform: translateY(-50%);
    color: #EED8D1;
    font-size: 24px;
  }
  
  .login-form select::-ms-expand {
    display: none;
  }
  
  .login-form select option {
    background-color: rgba(0, 0, 0, 0.5);
    color: #EED8D1;
  }
  
  .login-button {
    padding: 10px 18px;
    border: 2px solid #EED8D1;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #EED8D1;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    display: block;
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Gotham Bold', sans-serif;
  }
  
  .login-submit-button {
    margin-top: 20px;
    padding: 10px 18px;
    border: 2px solid #EED8D1;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #EED8D1;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    display: block;
    width: 140px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Gotham Bold', sans-serif;
  }
  
  .phone-wrapper {
    display: flex;
    align-items: center;
    padding-top: 0; /* Removed top padding */
    padding-bottom: 0;
  }
  
  .country-code-select {
    border: none !important;
    background-color: transparent;
    color: #EED8D1;
    font-size: 16px;
    padding: 10px;
    margin-right: 10px;
    width: 90px !important;
    flex: 0 0 auto;
    appearance: none;
    outline: none;
    box-shadow: none;
  }
  
  .phone-wrapper input {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: transparent;
    color: #EED8D1;
    font-size: 16px;
    box-shadow: none;
    margin-left: 10px;
    outline: none;
  }
  
  .waitlist-text-login {
    color: #EED8D1;
    margin-top: 20px;
    font-size: 14px;
    font-family: 'Gotham Light', sans-serif;
  }
  
  .waitlist-button-login {
    position: fixed;
    bottom: 25px;
    right: 25px;
    padding: 25px;
    border: none;
    border-radius: 20px;
    background-color: #FA8F21;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Gotham Bold', sans-serif;
  }
  
  /* Rotate the dropdown icon when the dropdown is open */
  .dropdown-icon.open,
  .dropdown-icon2.open {
    transform: rotate(180deg);
  }
  
  .dropdown-toggle {
    cursor: pointer;
  }
  
  /* Optional styling for disabled select to hide the dropdown options */
  select:disabled {
    pointer-events: none;
    background-color: transparent;
    color: inherit;
  }
  /* For react-select version 5.x and above */
  .react-select .css-1pahdxg-control {
    background: transparent; /* Make background transparent */
    border: 2px solid #EED8D1; /* Border color */
    color: #EED8D1; /* Text color */
  }
  
  .react-select .css-1pahdxg-control:hover {
    border-color: #EED8D1; /* Border color on hover */
  }
  
  .react-select .css-1s2u09g-control {
    background: transparent; /* Ensure control background is transparent */
  }
  
  .react-select .css-1s2u09g-menu {
    background: transparent; /* Make the dropdown menu background transparent */
    border: 2px solid #EED8D1; /* Optional: border for the dropdown menu */
  }
  
  .react-select .css-14e5y1a {
    color: #EED8D1; /* Text color inside the dropdown */
  }
  
  .react-select .css-1g6gooi {
    color: #EED8D1; /* Color for selected option */
  }
  
  .react-select .css-1t6w8f2 {
    color: #EED8D1; /* Color for the placeholder text */
  }
  
  /* Ensure the dropdown icon is styled appropriately */
  .react-select .css-1wa3eu0-placeholder {
    color: #EED8D1; /* Placeholder text color */
  }
  
  .react-select .css-1s2u09g-menu {
    box-shadow: none; /* Remove dropdown shadow if present */
  }
  
  .react-select .css-1t6w8f2, .react-select .css-14e5y1a, .react-select .css-1g6gooi {
    color: #EED8D1; /* Text color for options */
  }
  
  .css-1u9des2-indicatorSeparator {
    width: 0; /* Hide the separator by setting width to 0 */
    background-color: transparent; /* Optional: Make background transparent */
    margin: 0; /* Optional: Remove margins if needed */
  }
  /* Responsive styles for mobile devices */
  @media (max-width: 768px) {
    .background-image-desktop-login {
      width: 40%;
      top: 20%;
      transform: translate(-50%, -50%);
  }
    .login-form {
      background-color: rgba(0, 0, 0, 0.5);
      border: 2px solid #EED8D1;
      border-radius: 30px;
      padding: 0 20px;
      width: 290px;
      margin: 0 auto;
      display: inline-block;
      box-sizing: border-box;
    }
    .login-content {
      position: relative;
      z-index: 1;
      text-align: center;
      margin-top: 50px;
  }
  
    .waitlist-button-login {
      position: static; /* Remove fixed positioning */
      padding: 16px;
      border: none;
      border-radius: 30px;
      background-color: #FA8F21;
      color: #FFFFFF;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      font-family: 'Gotham Bold', sans-serif;
      width: 130px;
      margin: 20px auto 0 auto; /* Center horizontally and add margin at the top */
      display: block; /* Ensure the button is displayed as a block element */
    }
    .login-link {
      color: #EED8D1;
      text-decoration: none;
      display: block;
      margin-top: 50px;
  }
  }
  .login-link {


    color: #EED8D1; /* Set this to your preferred color */
    text-decoration: none; /* Remove underline if not needed */
  }
  
  .login-link:hover,
  .login-link:focus {
    color: #EED8D1; /* Ensure color stays the same on hover/focus */
    text-decoration: none; /* Remove underline if not needed */
  }
  .password{
    border: none;
    appearance: none;
    outline: none;
 
  }
  
/* For smaller screens like Asus E410M (1366px or smaller) */
@media (max-width: 1366px) {
  .login-content {
      margin-top: 280px; /* 300px for smaller screens like Asus E410M */
  }
}

/* For larger screens like Acer Nitro 5 (1920px or larger) */
@media (min-width: 1920px) {
  .login-content {
      margin-top: 600px; /* 600px for larger screens like Acer Nitro 5 */
  }
}

  /* Optional: For smaller devices (e.g., phones) */
  @media (max-width: 480px) {
    .background-image-desktop-login{
      top: 18%;
      width: 40%;
      margin-top: 100px;
    }
  
    .waitlist-text-login {
      display: none; /* Hide waitlist text on smaller screens */
    }
    .login-content {
      position: relative;
      z-index: 1;
      text-align: center;
      margin-top: 400px;
  }
  .login-button, 
  .login-submit-button {
    font-size: 15px;
    padding: 6px 12px;
    width: 90px;
  }
  .waitlist-button-login {
    position: static; /* Remove fixed positioning */
    padding: 16px;
    border: none;
    border-radius: 30px;
    background-color: #FA8F21;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Gotham Bold', sans-serif;
    width: 280px !important;
    margin: 20px auto 0 auto; /* Center horizontally and add margin at the top */
    display: block; /* Ensure the button is displayed as a block element */
  }
  .join-button{
      margin-right: 100px !important;
  }
  .login-link {
    color: #EED8D1;
    text-decoration: none;
    display: block;
    margin-top: 0;
}
    .login-form {
      background-color: rgba(0, 0, 0, 0.5);
      border: 2px solid #EED8D1;
      border-radius: 30px;
      width: 280px; /* Further decrease form width */
      padding: 8px 10px; /* Adjust padding */
      margin: 0 auto;
      display: inline-block;
      box-sizing: border-box;
    }
    .waitlist-text-login {
      display: none; /* Hide waitlist text on smaller screens */
    }
    .waitlist-button-login {
      position: static; /* Remove fixed positioning */
      padding: 16px;
      border: none;
      border-radius: 35px;
      background-color: #FA8F21;
      color: #FFFFFF;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      font-family: 'Gotham Bold', sans-serif;
      width: 130px;
      margin: 80px auto 0 auto; /* Center horizontally and add margin at the top */
      display: block; /* Ensure the button is displayed as a block element */
    }
  }
  