/* Keyframe Animation */
@keyframes scroll-left {
    from {
      transform: translateX(0); /* Start immediately with content */
    }
    to {
      transform: translateX(-100%); /* Scroll out fully */
    }
  }
  
  /* Base Styling for the Scroll Animation */
  .scroll-animation {
    display: flex;
    white-space: nowrap;
    animation: scroll-left 20s linear infinite; /* Default speed */
  }
  
  /* Scroll Items */
  .scroll-item {
    margin-right: 2rem; /* Consistent spacing */
  }
  
  /* Adjust speed for smaller screens */
  @media (max-width: 768px) {
    .scroll-animation {
      animation-duration: 10s; /* Faster speed for mobile */
    }
  }
  