/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import the GothamLight font */
@font-face {
  font-family: "GothamLight";
  src: url("./Gotham-Font/GothamLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "GothamUltraItalic";
  src: url("./Gotham-Font/Gotham-UltraItalic.otf") format("opentype");
  font-weight: 900; /* Adjust based on the actual weight of UltraItalic */
  font-style: italic;
}

/* Import the GothamBlack font */
@font-face {
  font-family: "GothamBlack";
  src: url("./Gotham-Font/Gotham-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* Import the GothamBold font */
@font-face {
  font-family: "GothamBold";
  src: url("./Gotham-Font/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

/* Import the GothamMedium font */
@font-face {
  font-family: "GothamMedium";
  src: url("./Gotham-Font/GothamMedium.ttf") format("truetype");
  font-weight: 500; /* Adjust the font-weight according to the actual weight */
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.custom-flex-grow {
  flex-grow: 1;
  background-color: #fdf5f2;
}
/* index.css */
body {
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Ensures the body takes at least the full viewport height */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  background-color: #fdf5f2;
}

body::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}
input:-webkit-autofill {
  background-color: #fdf5f2 !important; /* Match your input background */
  color: #3d291d !important; /* Match your text color */
  box-shadow: 0 0 0 1000px #fdf5f2 inset !important;
  -webkit-text-fill-color: #3d291d !important; /* Force text color */
  font-family: "Gotham Light", sans-serif; /* Match your font */
  border-radius: 9999px; /* Same as your custom input style */
}

.navbar-gradient {
  background: transparent; /* Make background transparent */
  position: absolute; /* Position over the hero image */
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Ensure it's above other content */
}
.navbar-gradient2 {
  background: transparent; /* Make background transparent */
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Ensure it's above other content */
}


@media (max-width: 1024px) {
  /* Mobile version */

}
@media (max-width: 640px) {
  /* or the breakpoint you want to target */
  .flex-nowrap {
    display: flex;
    flex-wrap: nowrap;
  }
}
.mobile-menu {
  position: absolute;
  top: 0.75rem; /* This corresponds to Tailwind 'top-3' */
  right: 1rem; /* This corresponds to Tailwind 'right-4' */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}

/* Show the mobile menu only on mobile devices */
@media (min-width: 768px) {
  .mobile-menu {
    display: none; /* Hide the menu on screens larger than 768px (desktop) */
  }
}

.auth-links {
  display: flex;
  gap: 0.5rem; /* This corresponds to Tailwind 'space-x-2' */
}

/* Adjust the position of the links */
.auth-links {
  margin-right: 1rem; /* Move both links slightly to the left */
}

.signup-link {
  color: #fa8f21;
  font-family: "Gotham Bold", sans-serif; /* Use your specific font-family */
  font-size: 0.75rem; /* This corresponds to Tailwind 'text-xs' */
  font-weight: bold; /* Make the Sign Up link bold */
  text-decoration: none;
  padding: 0; /* Set padding to 0 */
}

.login-link {
  color: white;
  font-family: "Gotham Light", sans-serif; /* Use your specific font-family */
  font-size: 0.75rem; /* This corresponds to Tailwind 'text-xs' */
  text-decoration: none;
  padding: 0; /* Set padding to 0 */
}
.login-link2 {
  color: #0F2B2A;
  font-family: "Gotham Light", sans-serif; /* Use your specific font-family */
  font-size: 0.75rem; /* This corresponds to Tailwind 'text-xs' */
  text-decoration: none;
  padding: 0; /* Set padding to 0 */
}
.login-link3 {
  color: #3D291D;
  font-family: "Gotham Light", sans-serif; /* Use your specific font-family */
  font-size: 0.75rem; /* This corresponds to Tailwind 'text-xs' */
  text-decoration: none;
  padding: 0; /* Set padding to 0 */
}
/* Desktop styles */
.signup-link-desktop,
.login-link-desktop,
.login-link-desktop2,
.login-link-desktop3{
  display: none; /* Hide by default */
}

@media (min-width: 1024px) {
  /* lg screen size and above */
  .signup-link-desktop,
  .login-link-desktop,
  .login-link-desktop2,
  .login-link-desktop3{
    display: inline-block; /* Show on large screens */
    font-size: 0.875rem; /* Tailwind 'text-sm' equivalent */
    text-decoration: none; /* Remove underline */
    padding: 0; /* No padding */
  }

  .signup-link-desktop {
    color: #fa8f21;
    font-family: "Gotham Bold", sans-serif; /* Custom font for Sign Up */
    font-weight: bold; /* Make the Sign Up link bold */
    margin-right: 0.5rem; /* Tailwind 'mr-2' equivalent */
  }

  .login-link-desktop {
    color: white;
    font-family: "Gotham Light", sans-serif; /* Custom font for Log In */
  }
  .login-link-desktop2 {
    color:#0F2B2A;
    font-family: "Gotham Light", sans-serif; /* Custom font for Log In */
  }
  .login-link-desktop3 {
    color:#3D291D;
    font-family: "Gotham Light", sans-serif; /* Custom font for Log In */
  }
}

/* Styles for code blocks */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
