/* Define the font-face for Gotham Light */
@font-face {
  font-family: 'Gotham Light';
  src: url('../../Gotham-Font/Gotham-Light.otf') format('opentype');
  font-weight: 100; /* Light weight */
  font-style: normal;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('../../Gotham-Font/Gotham-Bold.otf') format('opentype');
  font-weight: bold; /* Bold weight */
  font-style: normal;
}

  /* Specific to input fields in the login form */
  .signup-form input:-webkit-autofill {
    background-color: transparent !important;
    box-shadow: none !important;
    -webkit-text-fill-color: #EED8D1 !important; /* Force text color */
    color: #EED8D1 !important; /* Set the desired text color */
    transition: background-color 5000s ease-in-out 0s; /* Ensures no change in background color */
    font-family: 'Gotham Light', sans-serif;
  }
  
  /* Specific for Chrome autofill border */
  .signup-form input:-webkit-autofill::first-line {
    color: #EED8D1 !important; /* Set the desired text color */
    -webkit-text-fill-color: #EED8D1 !important; /* Force text color */
    font-family: 'Gotham Light', sans-serif;
  }
  
/* Apply Gotham Light to the form and all text */
body, .signup-form input, .signup-form select, .waitlist-text {
  font-family: 'Gotham Light', sans-serif;
}

/* Apply Gotham Bold to buttons */
.signup-button, .signup-heading, .signup-submit-button, .waitlist-button-signup {
  font-family: 'Gotham Bold', sans-serif;
}
.signup-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column; /* Ensure content is stacked vertically */
  background-image: url(../../Assets/1@4x\ 1.png);
  justify-content: center;
  align-items: center;
}

.background-image-desktop-signup {
  position: absolute;
  top: 100px; /* Adjust the value based on the height of your navbar */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 13%;
  height: auto;
  object-fit: cover;
  z-index: 0; /* Keeps the background image below the content */
}

.signup-content {
  position: relative;
  z-index: 1; /* Make sure the signup content appears above the background */
  text-align: center;
  margin-top: 150px;
}

.signup-heading {
  color: #EED8D1;
  font-size: 13px;
  text-decoration: underline;
}

.signup-form {
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid #EED8D1;
  border-radius: 30px;
  padding: 0 20px; /* Removed top and bottom padding */
  width: 260px;
  margin: 0 auto;
  display: inline-block;
  box-sizing: border-box;
}

.signup-navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2; /* Ensure navbar is on top of the background image */
}

.signup-form input,
.signup-form select {
  width: calc(100% - 27px);
  padding: 10px;
  margin-bottom: 0; /* Removed margin-bottom */
  border: none;
  border-bottom: 2px solid #EED8D1;
  border-radius: 0;
  background-color: transparent;
  color: #EED8D1;
  box-shadow: none;
  font-size: 13px;
  padding: 11px;
  outline: none; /* Remove outline for focus state */
  font-family: 'Gotham Light', sans-serif;
}
/* .waitlist-button-wrapper{
  margin-top: 100px;
} */

.signup-form input::placeholder,
.signup-form select {
  color: #EED8D1;
}

.select-wrapper {
  position: relative;
}

.select-wrapper select {
  border-bottom: 2px solid #EED8D1;
  color: #EED8D1;
  font-size: 16px;
  background: transparent;
  appearance: none;
  outline: none;
  padding: 15px;
  padding-right: 40px;
  width: 103%;
  box-sizing: border-box;
}

.country-select {
  padding: 15px;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #EED8D1;
  font-size: 24px;
}

.dropdown-icon2 {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjusted for better alignment */
  transform: translateY(-50%);
  color: #EED8D1;
  font-size: 24px;
}

.signup-form select::-ms-expand {
  display: none;
}

.signup-form select option {
  background-color: rgba(0, 0, 0, 0.5);
  color: #EED8D1;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

/* Basic input styling */
input {
  background-color: transparent;
  color: #EED8D1;
  border: 2px solid #EED8D1;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Gotham Light', sans-serif;
  font-size: 16px;
}

/* Adjust placeholder styling */
input::placeholder {
  color: #EED8D1;
}


.signup-button {
  background-color: #00000080;
  border: 2px solid #eed8d1;
  border-radius: 25px;
  color: #eed8d1;
  cursor: pointer;
  display: block;
  font-family: Gotham Bold, sans-serif;
  font-size: 15px;
  font-weight: 700;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 8px 18px;
  width: 90px;
}

.signup-submit-button {
  margin-top: 20px;
  padding: 8px 18px;
  border: 2px solid #EED8D1;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #EED8D1;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  display: block;
  width: 90px;
  margin-left: auto;
  margin-right: auto;
  height: 35px;
  font-family: 'Gotham Bold', sans-serif;
}

.phone-wrapper {
  display: flex;
  align-items: center;
  padding-top: 0; /* Removed top padding */
  padding-bottom: 0;
}

.phone-wrapper input[type="tel"] {
  border: none; /* Remove default borders */
  border-bottom: 2px solid #EED8D1; /* Add bottom border */
  background-color: transparent; /* Make background transparent */
  color: #EED8D1; /* Text color */
  padding: 10px 12px; /* Adjust padding as needed */
  width: 100%; /* Full width */
}

.input-no-border-bottom {
  border-bottom: none !important; /* Removes the bottom border */
}


.phone-wrapper input[type="tel"]:focus {
  outline: none; /* Remove default focus outline */
  border-bottom: 2px solid #EED8D1; /* Maintain bottom border color */
}


.country-code-select {
  border: none !important;
  background-color: transparent;
  color: #EED8D1;
  font-size: 16px;
  padding: 10px;
  margin-right: 10px;
  width: 90px !important;
  flex: 0 0 auto;
  appearance: none;
  outline: none;
  box-shadow: none;
}

.phone-wrapper input {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #EED8D1;
  font-size: 16px;
  box-shadow: none;
  margin-left: 10px;
  outline: none;
}

.waitlist-text-signup {
  color: #EED8D1;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Gotham Light', sans-serif;
}

.waitlist-button-signup {
  position: fixed;
  bottom: 25px;
  right: 25px;
  padding: 25px;
  border: none;
  border-radius: 20px;
  background-color: #FA8F21;
  color: #FFFFFF;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Gotham Bold', sans-serif;
}

/* Rotate the dropdown icon when the dropdown is open */
.dropdown-icon.open,
.dropdown-icon2.open {
  transform: rotate(180deg);
}

.dropdown-toggle {
  cursor: pointer;
}

/* Optional styling for disabled select to hide the dropdown options */
select:disabled {
  pointer-events: none;
  background-color: transparent;
  color: inherit;
}
/* For react-select version 5.x and above */
.react-select .css-1pahdxg-control {
  background: transparent; /* Make background transparent */
  border: 2px solid #EED8D1; /* Border color */
  color: #EED8D1; /* Text color */
}

.react-select .css-1pahdxg-control:hover {
  border-color: #EED8D1; /* Border color on hover */
}

.react-select .css-1s2u09g-control {
  background: transparent; /* Ensure control background is transparent */
}

.react-select .css-1s2u09g-menu {
  background: transparent; /* Make the dropdown menu background transparent */
  border: 2px solid #EED8D1; /* Optional: border for the dropdown menu */
}

.react-select .css-14e5y1a {
  color: #EED8D1; /* Text color inside the dropdown */
}

.react-select .css-1g6gooi {
  color: #EED8D1; /* Color for selected option */
}

.react-select .css-1t6w8f2 {
  color: #EED8D1; /* Color for the placeholder text */
}

/* Ensure the dropdown icon is styled appropriately */
.react-select .css-1wa3eu0-placeholder {
  color: #EED8D1; /* Placeholder text color */
}

.react-select .css-1s2u09g-menu {
  box-shadow: none; /* Remove dropdown shadow if present */
}

.react-select .css-1t6w8f2, .react-select .css-14e5y1a, .react-select .css-1g6gooi {
  color: #EED8D1; /* Text color for options */
}

.css-1u9des2-indicatorSeparator {
  width: 0; /* Hide the separator by setting width to 0 */
  background-color: transparent; /* Optional: Make background transparent */
  margin: 0; /* Optional: Remove margins if needed */
}
/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .background-image-desktop-signup{
    width: 40%;
    top: 15%;
    transform: translate(-50%, -50%);
    margin-top: 50px;
}
 .signup-form {
    width: 70%; /* Further increase width on very small screens */
    padding: 0 5px; /* Further reduce padding */
  }
  .signup-form {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #EED8D1;
    border-radius: 30px;
    padding: 0 20px;
    width: 290px;
    margin: 0 auto;
    display: inline-block;
    box-sizing: border-box;
  }
  .signup-content {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 100px;
}
.waitlist-text-signup {
  display: none; /* Hide waitlist text on smaller screens */
}
  .waitlist-button-signup {
    position: static; /* Remove fixed positioning */
    padding: 16px;
    border: none;
    border-radius: 30px;
    background-color: #FA8F21;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 30px !important;
    font-family: 'Gotham Bold', sans-serif;
    width: 280px !important;
    margin: 20px auto 0 auto; /* Center horizontally and add margin at the top */
    display: block; /* Ensure the button is displayed as a block element */
  }
  .join-button{
      margin-right: 100px !important;
  }
}
/* For smaller screens like Asus E410M (1366px or smaller) */
@media (max-width: 1366px) {
  .background-image-desktop-signup {
    top: 150px; /* 150px for smaller screens like Asus E410M */
  }
  .signup-container {
    height: 130vh;
  }

}

/* For larger screens like Acer Nitro 5 (1920px or larger) */
@media (min-width: 1920px) {
  .background-image-desktop-signup {
    top: 300px; /* 300px for larger screens like Acer Nitro 5 */
  }
  .signup-container {
    height: 100vh;
  }
}

/* Optional: For smaller devices (e.g., phones) */
@media (max-width: 480px) {
  .signup-button, .signup-submit-button {
    background-color: #00000080;
    border: 2px solid #eed8d1;
    border-radius: 25px;
    color: #eed8d1;
    cursor: pointer;
    display: block;
    font-family: Gotham Bold, sans-serif;
    font-size: 15px;
    font-weight: 700;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 8px 18px;
    width: 100px;
}
signup-submit-button {
  width: 100px;
}
  .background-image-desktop-signup {
    width: 40%; /* Increase the width further for very small screens */
    top: 16%;
  }
  .waitlist-text-signup {
    display: none; /* Hide waitlist text on smaller screens */
  }
   .waitlist-button-wrapper{
  margin-top: 20px;
} 
  .signup-content {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 125px;
}
  .signup-form {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #EED8D1;
    border-radius: 30px;
    padding: 0 20px;
    width: 250px;
    margin: 0 auto;
    display: inline-block;
    box-sizing: border-box;
  }

  .waitlist-button-signup {
    position: static; /* Remove fixed positioning */
    padding: 16px;
    border: none;
    border-radius: 30px;
    background-color: #FA8F21;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Gotham Bold', sans-serif;
    width: 130px;
    margin-top: 30px !important;
    margin: 20px auto 0 auto; /* Center horizontally and add margin at the top */
    display: block; /* Ensure the button is displayed as a block element */
  }
}
